const env = process.env.NODE_ENV;
const urlMap = {
  development: 'http://192.168.3.123:9999',
  // development: 'https://ed.zhidianfan.com',
  // development: 'http://edtest.c39eb1826bc29416a82e276880425b0e0.cn-hangzhou.alicontainer.com',
  production: 'https://ed.zhidianfan.com',
  // production: 'https://edtest.zhidianfan.com',
  test: 'http://edtest.c39eb1826bc29416a82e276880425b0e0.cn-hangzhou.alicontainer.com',
  local: 'http://lr.vaiwan.com/'
};
export const ip_url = urlMap[env];
export const base_url = ip_url + '/api';
const pcUrlMap = {
  // development: 'http://eda.c39eb1826bc29416a82e276880425b0e0.cn-hangzhou.alicontainer.com',
  development: 'http://192.168.3.123:8080',
  production: 'https://hm.zhidianfan.com',
  test: 'http://eda.c39eb1826bc29416a82e276880425b0e0.cn-hangzhou.alicontainer.com',
  local: 'http://lr.vaiwan.com/'
};
export const pc_url = pcUrlMap[env];
