import axios from 'axios';
import { Message, MessageBox } from 'element-ui';
import { base_url } from "@/config";
import { store } from "@/store";
import { setStorage } from "@/utils/storage";
import { post_errorLog } from "@/api/common";

const CancelToken = axios.CancelToken;
const source = CancelToken.source();

const urls = ['/token/v1/getTokenWithCode','/log/v1/saveErrorLog'] // 不需要弹全局错误的接口url
const urls2 = ['/token/v1/getTokenWithCode','/log/v1/saveErrorLog'] // 不需要弹全局错误的接口url

const instance = axios.create({
  baseURL: base_url,
  timeout: 10000
})
instance.defaults.headers.post['Content-Type'] = 'application/json';

// request拦截
instance.interceptors.request.use(function (config) {
  if (store.state.token) {
    config.headers.token = store.state.token || '';
  }
  config.headers.clientType = 'DINGYI_MANAGER'
  config.cancelToken = source.token;
  return config;
}, function (error) {
  return Promise.reject(error);
});

// response拦截
instance.interceptors.response.use(function (response) {
  const { code, data, msg } = response.data;
  const { url } = response.config;

  if (code == 401401 || code == 401402) {
    source.cancel()
    MessageBox.alert('登录状态失效，请重新登录。', '温馨提示', {
      showClose: false,
      confirmButtonText: '重新登录',
      callback: () => {
        setStorage('token', '');
        location.reload()
      }
    })
    return Promise.reject('无权限')
  } else if (code !== 0) {
    if (urls.indexOf(url) < 0 && response.config.showMsg) {
      Message.error({
        showClose: true,
        message: msg || '请求错误'
      })
    }
    if(urls2.indexOf(url) < 0) { //埋点本身不请求
      let _param = {
        enterData: response.config.method === 'post'?{...JSON.parse(response.config.data)}:{...response.config.params},
        errMsg: response.data.msg,
        hotelInfo: {...store.state.userInfo.hotelInfo},
      } || {}
      post_errorLog({
        url: url || '',
        param: JSON.stringify(_param),
        clientType: 'DINGYI_MANAGER'
      }).then(() => {return})
    }
    return Promise.reject(response.data)
  }
  return data;
}, function (error) {
  if (error == "Cancel") {
    return new Promise(() => {});
  }
  let msg = '网络错误，请重试';
  if ((typeof error === 'string') && (error.indexOf('timeout') > -1)) {
    msg = '请求超时，请重试!'
  }
  Message.error({
    showClose: true,
    message: error || msg
  })
  return Promise.reject(error || msg);
});

export const ajax = function ({
  baseURL,
  url = '',
  method = 'get',
  params = {},
  data = {},
  headers = {},
  timeout = 10000,
  showMsg =  true
}) {
  return instance({
    baseURL,
    url,
    method: method || 'get',
    params: params || {},
    data: data || {},
    headers: headers || {},
    timeout: timeout,
    showMsg: showMsg
  })
}
