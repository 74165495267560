<template>
  <div class="slide-bar scroll flex flex-column">
    <div class="logo" @click="handleSysConfig">
      <img src="../../../assets/img/logo.png" width="25" alt="">
      <span class="slide-title">鼎壹运维管理后台</span>
    </div>
    <el-menu
      :default-active="defaultActive"
      router
      class="flex-1"
      @select="handleMenuSelect"
    >
      <div
          v-for="n in navList"
          :key="n.id">
        <el-submenu
          :index="n.id + ''"
          v-if="n.children && n.children.length > 0"
        >
          <template slot="title">
            <img class="show-img" :src="n.icon" width="14" alt="">
            <img class="hide-img" :src="n.active" width="14" alt="">
            <span class="label">
              {{n.label}}
            </span>
          </template>
          <el-menu-item
            v-for="c in n.children"
            :key="c.id"
            :index="c.path"
          >{{c.label}}</el-menu-item>
        </el-submenu>
        <el-menu-item
          :index="n.path"
          v-else
        >
          <template slot="title">
            <img class="show-img" :src="(n.path === defaultActive ? n.active : n.icon)" width="14" alt="">
            <img class="hide-img" :src="n.active" width="14" alt="">
            <span class="label">
              {{n.label}}
            </span>
          </template>
        </el-menu-item>
      </div>
    </el-menu>
    <div class="logo-bottom text-center">
      <div class="beian" >
        
        <div style="margin-top:5px"><img src="https://download-dy.oss-cn-hangzhou.aliyuncs.com/beian.png" style="width:13px;margin-right: 5px;display: inline-block;vertical-align: -3px;"><a href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=33021202001232" target="_blank" class="a-link">浙公网安备33021202001232号</a></div>
        <div><a href="https://beian.miit.gov.cn/" target="_blank" class="a-link">浙ICP备16031560号-1</a></div>
      </div>
    </div>
  </div>
</template>

<script>
import { navList } from "@/config/nav";

export default {
  name: "slide-bar",
  data() {
    return {
      permissions: [],
      defaultActive: '/',
      count: 0
    }
  },
  computed: {
    navList() {
      const permissions = this.$store.state.permissions.map(i => {
        return i.code.split(':')[0]
      })
      const permissionsSecond = this.$store.state.permissions.map(i => {
        return i.code.split(':')[0] + ':' + i.code.split(':')[1]
      })
      let n = navList().filter(o => {
        return !o.permission || (o.permission && permissions.indexOf(o.permission)) > -1
      }).map(i => {
        if (i.children) {
          let children = i.children.concat()
          children = children.filter(v => {
            return !v.permission || (v.permission && permissionsSecond.indexOf(v.permission)) > -1
          })
          i.children = children
        }
        console.log(i.children,11)
        return i
      })
      return n
    }
  },
  watch: {
    $route() {
      this.initActive()
    }
  },
  methods: {
    initActive() {
      const { id, parentId } = this.$route.meta
      navList().map(o => {
        if (o.children) {
          const item = o.children.find(i => { return i.id == id || i.id == parentId })
          if (item) {
            this.defaultActive = item.path || '/'
          }
        } else {
          if (o.id == parentId) {
            this.defaultActive = o.path
          }
        }
      })
    },
    handleMenuSelect(index, indexPath) {
      console.log(index, indexPath)
      this.$store.commit('page/SET_KEEPALIVE', [])
      // this.defaultActive = index
    },
    handleSysConfig() {
      this.count++
      if(this.count >5) {
        this.count = 0
        this.$router.push({
          name: 'system-logs'
        })
      }
    }
  },
  mounted() {
    this.initActive()
  }
}
</script>

<style scoped lang="scss">
  .slide-bar {
    flex: 0 0 200px;
    overflow-y: auto;
    overflow-x: hidden;
    .logo {
      height: 50px;
      line-height: 50px;
      text-align: center;
    }
    .logo-bottom{
      color: #406283;
      font-size: 13px;
      border-top: 1px solid rgba(0,0,0, 0.08);
      .beian{
        margin-top: 20px;
        margin-bottom: 20px;
        font-size:12px;
        text-align: center;
        color: #777777;
        a{
          color: rgba(0,0,0, 0.5);
          text-decoration: none;
        }
        a:hover{
          text-decoration: underline;
        }
      }
    }
    .slide-title{
      font-size: 16px;
      color: #333;
      font-weight: bold;
      margin-left: 5px;
      display: inline-block;
      vertical-align: -2px;
    }
    ::v-deep .el-menu-item,
    ::v-deep .el-submenu__title{
      text-align: left;
      background: #FFFFFF !important;
      color: #212121 !important;
      border-right: 4px solid transparent !important;
      &:hover{
        background: #F3F4FB !important;
        border-right: 4px solid #536FFF !important;
        color: #536FFF !important;
        .hide-img{
          display: inline-block;
        }
        .show-img{
          display: none;
        }
      }
      &.is-active{
        background: #F3F4FB !important;
        border-right: 4px solid #536FFF !important;
        color: #536FFF !important;
      }
    }
    ::v-deep .el-submenu{
      &.is-active{
        .el-submenu__title{
          background: #F3F4FB !important;
          color: #536FFF !important;
        }
        .hide-img{
          display: inline-block;
        }
        .show-img{
          display: none;
        }
        .el-menu-item{
          background: #F3F4FB !important;
        }
        .el-menu-item.is-active{
          background: #DFE1EC !important;
        }
      }
    }
    .hide-img{
      display: none;
    }
    .label{
      margin-left: 8px;
    }
  }
</style>