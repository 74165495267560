export function deepClone(source) {
  if (!source && typeof source !== 'object') {
    throw new Error('error arguments', 'deepClone')
  }
  const targetObj = source.constructor === Array ? [] : {}
  Object.keys(source).forEach(keys => {
    if (source[keys] && typeof source[keys] === 'object') {
      targetObj[keys] = deepClone(source[keys])
    } else {
      targetObj[keys] = source[keys]
    }
  })
  return targetObj
}

export function convertHexToRGB(color) {
  if (color.length === 4) {
    let extendedColor = "#"
    for (let i = 1; i < color.length; i++) {
      extendedColor += color.charAt(i) + color.charAt(i)
    }
    color = extendedColor
  }
  const values = {
    r: parseInt(color.substr(1, 2), 16),
    g: parseInt(color.substr(3, 2), 16),
    b: parseInt(color.substr(5, 2), 16),
  }
  return `${values.r}, ${values.g}, ${values.b}`
}

export function objectToParams(param) {
  if (param == null) return '';
  var arr = [];
  for (var i in param) {
    arr.push(i + '=' + param[i])
  }
  return arr.join("&");
}

export function getFileType(url) {
  let fileType = ''
  if(url) {
    let _obj = url.split('.')
    fileType = _obj[_obj.length -1]
    if(fileType.toLowerCase() === 'jpg' || fileType.toLowerCase() === 'jpeg') {
      fileType = 'jpg'
    } else if (fileType.toLowerCase() === 'gif') {
      fileType = 'gif'
    } else if (fileType.toLowerCase() === 'png') {
      fileType = 'png'
    } else if (fileType.toLowerCase() === 'pdf') {
      fileType = 'pdf'
    } else if (fileType.toLowerCase() === 'doc' || fileType.toLowerCase() === 'dot' || fileType.toLowerCase() === 'docx') {
      fileType = 'doc'
    } else if (fileType.toLowerCase() === 'xls' || fileType.toLowerCase() === 'xlt' || fileType.toLowerCase() === 'xlsx') {
      fileType = 'xls'
    } else {
      fileType = ''
    }
  }
  return fileType
}