<template>
  <div class="index-header">
    <div class="brand-name">
      <el-dropdown
        @command="handle"
        style="margin-left: 15px;"
      >
        <span class="el-dropdown-link">
          <div class="dropdown-item">
            <div>
              <img :src="$store.state.userInfo.headImage" alt="" class="head-img" v-if="$store.state.userInfo.headImage" @click="handle('personal')">
              <div class="user-head" v-else @click="handle('personal')">{{firstName}}</div>
              <span class="nickName">{{$store.state.userInfo.realName}}</span>
              <i class="el-icon-arrow-down"></i>
            </div>
          </div>
        </span>
        <el-dropdown-menu slot="dropdown">
          <el-dropdown-item command="personal">个人中心</el-dropdown-item>
          <el-dropdown-item command="logout">退出登录</el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>
    </div>
  </div>
</template>

<script>
import {setStorage} from "@/utils/storage";
import img from "../../../assets/img/head-img.png"
import {save_login_logs} from "@/api/log";

export default {
  name: "index-header",
  data() {
    return {
      img
    }
  },
  computed: {
    firstName() {
      const name = this.$store.state.userInfo.realName
      if (name) {
        return name.substr(0, 1)
      } else {
        return ''
      }
    }
  },
  methods: {
    handle(c) {
      console.log('c', c);
      if (c === 'logout') {
        save_login_logs({
          clientType: 'DINGYI_MANAGER',
          loginType: 2,
          username: this.$store.state.userInfo.username
        })
        setStorage('token', '');
        location.reload()
      } else if (c === 'personal') {
        this.$router.push({
          name: 'personal'
        });
      }
      // this.$router.push({
      //   name: 'login'
      // });
    }
  }
}
</script>

<style scoped lang="scss">
  .index-header {
    flex: 0 0 50px;
    padding: 0 78px;
    background: #fff;
    box-shadow: 0px 2px 6px 0px rgba(192, 195, 197, 0.2);
    z-index: 1000;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    .brand-name {
      cursor: pointer;
      font-size: 14px;
      display: flex;
      align-items: center;
      .head-img{
        width: 24px;
        height: 24px;
        border-radius: 50%;
        display: inline-block;
      }
      .user-head{
        width: 24px;
        height: 24px;
        line-height: 24px;
        border-radius: 50%;
        background: #DFE8F9;
        color: #487AD5;
        text-align: center;
        display: inline-block;
        font-size: 12px;
      }
      .nickName{
        margin: 0 10px;
      }
      .dropdown-item {
        display: flex;
        align-items: center;
        text-align: left;
      }
      .admin-username {
        font-size: 14px;
      }
    }
  }
</style>
