export const setStorage = function (key, value) {
  let v = value;
  if (typeof v === 'object') {
    const map = localStorage['tmap'] ? JSON.parse(localStorage['tmap']) : {};
    v = JSON.stringify(v);
    map[key] = 1;
    localStorage['tmap'] = JSON.stringify(map);
  }
  localStorage[key] = v;
}

export const getStorage = function (key) {
  const v = localStorage[key];
  const map = localStorage['tmap'] ? JSON.parse(localStorage['tmap']) : {};
  if (map[key]) {
    return JSON.parse(v);
  }
  return v;
}