import Vue from 'vue';
import VueRouter from "vue-router";
import Empty from '../pages/empty';
import Index from '../pages/index';
import Login from '../pages/login';
import { getStorage, setStorage } from "@/utils/storage";
import { store } from "@/store";
// import { Message } from 'element-ui';

Vue.use(VueRouter)
// 避免到当前位置的冗余导航
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}

import moment from 'moment';
import { getPermission } from "@/api/permission";
import { refresh_token } from "@/api/login";

const Home = () => import(/* webpackChunkName: "home" */ '../pages/home');

const Product = () => import(/* webpackChunkName: "product" */ '../pages/product');
const Invoice = () => import(/* webpackChunkName: "Invoice" */ '../pages/finance/invoice');
const InvoiceDetail = () => import(/* webpackChunkName: "InvoiceDetail" */ '../pages/finance/invoice-detail');
const HotelSystemAuth = () => import(/* webpackChunkName: "HotelSystemAuth" */ '../pages/base/hotel-system-auth');
const BreadSystemAuth = () => import(/* webpackChunkName: "BreadSystemAuth" */ '../pages/base/bread-system-auth');
const SystemAuth = () => import(/* webpackChunkName: "SystemAuth" */ '../pages/user/system-auth');
const SystemRole = () => import(/* webpackChunkName: "SystemRole" */ '../pages/user/system-role');
const SystemUser = () => import(/* webpackChunkName: "SystemUser" */ '../pages/user/system-user');
const HotelList = () => import(/* webpackChunkName: "HotelList" */ '../pages/hotel/hotel-list');
const GroupList = () => import(/* webpackChunkName: "GroupList" */ '../pages/hotel/group-list');
const HotelEdit = () => import(/* webpackChunkName: "HotelEdit" */ '../pages/hotel/hotel-edit');
const GroupEdit = () => import(/* webpackChunkName: "GroupEdit" */ '../pages/hotel/group-edit');
const HotelRecharge = () => import(/* webpackChunkName: "HotelRecharge" */ '../pages/hotel/hotel-recharge-list');
const MarketList = () => import(/* webpackChunkName: "marketList" */ '../pages/market/marketer-list');
const HotelInitialization = () => import(/* webpackChunkName: "HotelInitialization" */ '../pages/base/hotel-initialization');
const DictionaryMaintenance = () => import(/* webpackChunkName: "DictionaryMaintenance" */ '../pages/base/dictionary-maintenance');
const AreaCode = () => import(/* webpackChunkName: "AreaCode" */ '../pages/base/area-code');
const Personal = () => import(/* webpackChunkName: "Personal" */ '../pages/personal/personal');
const OperationLogs = () => import(/* webpackChunkName: "Personal" */ '../pages/logs/operation-logs');
const LoginLogs = () => import(/* webpackChunkName: "Personal" */ '../pages/logs/login-logs');
const MinipList = () => import('../pages/miniprogram/minip-list');
const MinipEdit = () => import('../pages/miniprogram/minip-edit');
const MinipRecharge = () => import('../pages/miniprogram/minip-recharge-list');
const ClueList = () => import(/* webpackChunkName: "marketList" */ '../pages/market/clue-list');
const SystemNotice = () => import(/* webpackChunkName: "SystemAuth" */ '../pages/user/system-notice');
const SystemLogs = () => import(/* webpackChunkName: "systemLogs" */ '../pages/logs/system-logs');

const MeituanAuth = () => import('../pages/hotel/meituan-auth');
const WorkflowDetail = () => import('../pages/base/workflow/detail');
const RechargeRecord = () => import('../pages/finance/rechargeRecord')

const CustomMinipList = () => import('../pages/miniprogram/customMinip-list')
const CustomMinipEdit = () => import('../pages/miniprogram/customMinip-edit')


// 菜单icon
import HomeIcon from '@/assets/nav/business-w.png';
import HomeActiveIcon from '@/assets/nav/business-b.png'
import MarketIcon from '@/assets/nav/market-w.png';
import MarketActiveIcon from '@/assets/nav/market-b.png';
import ProductIcon from '@/assets/nav/product-w.png';
import ProductActiveIcon from '@/assets/nav/product-b.png';
import FinanceIcon from '@/assets/nav/finance-w.png';
import FinanceActiveIcon from '@/assets/nav/finance-b.png';
import UserIcon from '@/assets/nav/user-w.png';
import UserActiveIcon from '@/assets/nav/user-b.png';
import BaseIcon from '@/assets/nav/data-w.png';
import BaseActiveIcon from '@/assets/nav/data-b.png';
import logsActiveIcon from '@/assets/nav/logs-w.png';
import logsIcon from '@/assets/nav/logs-b.png';
import minipIcon from '@/assets/nav/minip-w.png';
import minipActiveIcon from '@/assets/nav/minip-b.png';

export const routes = [
  {
    path: '/dashboard',
    name: 'dashboard',
    component: Home,
    meta: {
      title: '首页'
    }
  },
  {
    path: '/hotel/hotel-list',
    name: 'hotel-list',
    component: HotelList,
    icon: HomeIcon,
    active: HomeActiveIcon,
    meta: {
      id: 11,
      parentId: 1,
      menuName: '酒店管理',
      menu: true,
      permission: 'hotel:manager',
      title: '门店列表'
    }
  },
  {
    path: '/hotel/group-list',
    name: 'group-list',
    component: GroupList,
    icon: HomeIcon,
    active: HomeActiveIcon,
    meta: {
      id: 13,
      parentId: 1,
      menuName: '酒店管理',
      menu: true,
      permission: 'hotel:manager',
      title: '集团列表'
    }
  },
  {
    path: '/hotel/hotel-edit',
    name: 'hotel-edit',
    component: HotelEdit,
    meta: {
      id: 111,
      parentId: 11,
      menuName: '酒店管理',
      permission: 'hotel:manager',
      title: '门店配置'
    }
  },
  {
    path: '/hotel/group-edit',
    name: 'group-edit',
    component: GroupEdit,
    meta: {
      id: 112,
      parentId: 13,
      menuName: '酒店管理',
      permission: 'hotel:manager',
      title: '集团配置'
    }
  },
  {
    path: '/hotel/hotel-recharge',
    name: 'hotel-recharge',
    component: HotelRecharge,
    meta: {
      id: 12,
      parentId: 1,
      menuName: '酒店管理',
      menu: true,
      permission: 'hotel:manager',
      title: '续期记录'
    }
  },
  {
    path: '/minip/minip-list',
    name: 'minip-list',
    component: MinipList,
    icon: minipIcon,
    active: minipActiveIcon,
    meta: {
      id: 21,
      parentId: 2,
      menuName: '宴荟佳小程序管理',
      menu: true,
      permission: 'minip:list',
      title: '小程序列表'
    }
  },
  {
    path: '/minip/minip-edit',
    name: 'minip-edit',
    component: MinipEdit,
    meta: {
      id: 211,
      parentId: 21,
      menuName: '宴荟佳小程序管理',
      permission: 'minip:list',
      title: '小程序详情'
    }
  },
  {
    path: '/minip/minip-recharge',
    name: 'minip-recharge',
    component: MinipRecharge,
    meta: {
      id: 22,
      parentId: 2,
      menuName: '宴荟佳小程序管理',
      menu: true,
      permission: 'minip:renew',
      title: '续期记录'
    }
  },
  {
    path: '/minip/customMinip-list',
    name: 'customMinip-list',
    component: CustomMinipList,
    icon: minipIcon,
    active: minipActiveIcon,
    meta: {
      id: 24,
      parentId: 3,
      menuName: '定制小程序管理',
      menu: true,
      permission: 'customMinip:customList',
      title: '定制小程序列表'
    }
  },
  {
    path: '/minip/customMinip-edit',
    name: 'customMinip-edit',
    component: CustomMinipEdit,
    icon: minipIcon,
    meta: {
      id: 212,
      parentId: 3,
      menuName: '定制小程序管理',
      permission: 'customMinip:customList',
      title: '定制小程序详情'
    }
  },
  {
    path: '/market/list',
    name: 'market-list',
    component: MarketList,
    icon: MarketIcon,
    active: MarketActiveIcon,
    meta: {
      id: 31,
      parentId: 4,
      menuName: '销售管理',
      menu: true,
      permission: 'market:manager',
      title: '直销列表'
    }
  },{
    path: '/market/clueList',
    name: 'clue-list',
    component: ClueList,
    icon: MarketIcon,
    active: MarketActiveIcon,
    meta: {
      id: 32,
      parentId: 4,
      menuName: '销售管理',
      menu: true,
      permission: 'market:customerClue',
      title: '客户线索列表'
    }
  },
  {
    path: '/product',
    name: 'product',
    component: Product,
    icon: ProductIcon,
    active: ProductActiveIcon,
    meta: {
      id: 4,
      parentId: 5,
      menuName: '产品管理',
      menu: true,
      permission: 'product:manager',
      title: '产品管理'
    }
  },
  {
    path: '/finance/invoice',
    name: 'finance-invoice',
    component: Invoice,
    icon: FinanceIcon,
    active: FinanceActiveIcon,
    meta: {
      id: 51,
      parentId: 6,
      menuName: '财务管理',
      menu: true,
      permission: 'finance:invoice',
      title: '开票管理'
    }
  },
  {
    path: '/finance/rechargeRecord',
    name: 'finance-rechargeRecord',
    component: RechargeRecord,
    icon: FinanceIcon,
    active: FinanceActiveIcon,
    meta: {
      id: 52,
      parentId: 6,
      menuName: '财务管理',
      menu: true,
      permission: 'finance:rechargeRecord',
      title: '短信充值记录'
    }
  },
  {
    path: '/finance/invoice/detail',
    name: 'finance-invoice-detail',
    component: InvoiceDetail,
    meta: {
      id: 511,
      parentId: 51,
      menuName: '财务管理',
      permission: 'finance:invoice',
      title: '开票详情'
    }
  },
  {
    path: '/user/system-user',
    name: 'system-user',
    component: SystemUser,
    icon: UserIcon,
    active: UserActiveIcon,
    meta: {
      id: 61,
      parentId: 7,
      menuName: '用户与权限管理',
      menu: true,
      permission: 'user:user',
      title: '用户管理'
    }
  },
  {
    path: '/user/system-notice',
    name: 'system-notice',
    component: SystemNotice,
    icon: UserIcon,
    active: UserActiveIcon,
    meta: {
      id: 64,
      parentId: 7,
      menuName: '用户与权限管理',
      menu: true,
      permission: 'user:notice',
      title: '通知管理'
    }
  },
  {
    path: '/user/system-role',
    name: 'system-role',
    component: SystemRole,
    meta: {
      id: 62,
      parentId: 7,
      menuName: '用户与权限管理',
      menu: true,
      permission: 'user:role',
      title: '角色管理'
    }
  },
  {
    path: '/user/system-auth',
    name: 'system-auth',
    component: SystemAuth,
    meta: {
      id: 63,
      parentId: 7,
      menuName: '用户与权限管理',
      menu: true,
      permission: 'user:auth',
      title: '权限配置'
    }
  },
  {
    path: '/base/hotel-system-auth',
    name: 'hotel-system-auth',
    component: HotelSystemAuth,
    icon: BaseIcon,
    active: BaseActiveIcon,
    meta: {
      id: 71,
      parentId: 8,
      menuName: '基础数据维护',
      menu: true,
      permission: 'base:auth',
      title: '酒店系统权限配置'
    }
  },
  {
    path: '/base/bread-system-auth',
    name: 'bread-system-auth',
    component: BreadSystemAuth,
    icon: BaseIcon,
    active: BaseActiveIcon,
    meta: {
      id: 75,
      parentId: 8,
      menuName: '基础数据维护',
      menu: true,
      permission: 'base:auth',
      title: '集团系统权限配置'
    }
  },
  {
    path: '/base/hotel-initialization',
    name: 'hotel-initialization',
    component: HotelInitialization,
    icon: BaseIcon,
    meta: {
      id: 72,
      parentId: 8,
      menuName: '基础数据维护',
      menu: true,
      permission: 'base:init',
      title: '酒店初始化设置'
    }
  },
  {
    path: 'setting/workflow-edit',
    name: 'workflow-edit',
    component: WorkflowDetail,
    meta: {
      id: 76,
      parentId: 8,
      menuName: '基础数据维护',
      menu: false,
      permission: 'base:init',
      title: '酒店初始化设置'
    }
  },
  {
    path: '/base/dictionary-maintenance',
    name: 'dictionary-maintenance',
    component: DictionaryMaintenance,
    icon: BaseIcon,
    meta: {
      id: 73,
      parentId: 8,
      menuName: '基础数据维护',
      menu: true,
      permission: 'base:dict',
      title: '数据字典维护'
    }
  },
  {
    path: '/base/area-code',
    name: 'area-code',
    component: AreaCode,
    icon: BaseIcon,
    meta: {
      id: 74,
      parentId: 8,
      menuName: '基础数据维护',
      menu: true,
      permission: 'base:areaCode',
      title: '地区码表'
    }
  },
  {
    path: '/personal/personal',
    name: 'personal',
    component: Personal,
    // icon: BaseIcon,
    meta: {
      id: 710,
      parentId: 0,
      menuName: '首页',
      menu: true,
      title: '个人中心'
    }
  },
  {
    path: '/logs/operation',
    name: 'operation-logs',
    component: OperationLogs,
    icon: logsIcon,
    active: logsActiveIcon,
    meta: {
      id: 81,
      parentId: 10,
      menuName: '日志管理',
      menu: true,
      permission: 'log:operation',
      title: '操作日志'
    }
  },
  {
    path: '/logs/login',
    name: 'login-logs',
    component: LoginLogs,
    icon: logsIcon,
    meta: {
      id: 82,
      parentId: 10,
      menuName: '日志管理',
      menu: true,
      permission: 'log:login',
      title: '登录日志'
    }
  },
  {
    path: '/logs/system',
    name: 'system-logs',
    component: SystemLogs,
    icon: logsIcon,
    meta: {
      id: 83,
      parentId: 10,
      menuName: '埋点问题列表',
      menu: false,
      title: '埋点问题列表',
    }
  },
]

export const validMenuRoutes = function() {
  const permissionsFirst = store.state.permissions.filter(i => {
    // 筛选一级菜单
    return i.code.split(':')[1] && i.code.split(':')[1] == 'manager'
  })
  const permissionsSecond = store.state.permissions.filter(n => {
    const a = n.code.split(':')[0]
    return permissionsFirst.findIndex(b => { return b.code.split(':')[0] == a }) > -1
  }).map(i => {
    return i.code.split(':')[0] + ':' + i.code.split(':')[1]
  })
  console.log(store.state.permissions,'permissionsSecond')
  const validRoutes = routes.filter(o => { return !(o.meta.permission && permissionsSecond.indexOf(o.meta.permission) < 0) })
  const enRoutes = validRoutes.filter(o => { return o.meta.menu })
  console.log('有权限的菜单', enRoutes)
  return enRoutes
}

export const router = new VueRouter({
  routes: [
    {
      path: '/login',
      name: 'login',
      component: Login
    },
    {
      path: '/meituanAuth',
      name: 'meituanAuth',
      component: MeituanAuth
    },
    {
      path: '/',
      component: Index,
      children: [
        ...routes,
        {
          path: '404',
          name: '404',
          component: Empty,
          meta: {
          }
        }
      ]
    },
    {
      path: '*',
      redirect: '/'
    }
  ]
})

router.beforeEach(async (to, from, next) => {
  const token = getStorage('token');
  // console.log('from', from);
  // console.log('to', to);
  if (to.name === 'meituanAuth') {
    return next()
  }
  if ((!token) && (to.name !== 'login')) {
    return next({
      name: 'login'
    })
  }

  if (token) {
    try {
      if (!store.state.permissions.length) {
        let permissions = await getPermission({
          clientType: "DINGYI_MANAGER",
          //非必填
          hotelId: ''
        }) || []
        store.commit('SET_PERMISSIONS', permissions)
      }
      console.log(store.state.permissions)
      // const menu = validMenuRoutes()
      // if (menu.length == 0) {
      //   Message.error({
      //     showClose: true,
      //     message: '无菜单权限'
      //   })
      // }
      if (to.path == '/') {
        return next({
          // name: menu[0].name
          name: 'dashboard'
        })
      }
      const permissionsSecond = store.state.permissions.map(i => {
        return i.code.split(':')[0] + ':' + i.code.split(':')[1]
      })
      if (to.meta.permission && permissionsSecond.indexOf(to.meta.permission) < 0) {
        return next({
          name: '404'
        })
      }
    } catch (err) {
      setStorage('token', '');
      console.log(err)
      return next({
        name: 'login'
      })
    }
  }

  next();
})

router.afterEach(() => {
  // 刷新token
  const token = getStorage('token');
  const refreshToken = getStorage('refreshToken');
  const expTime = getStorage('expTime');
  if (token) {
    var a = moment();
    var b = moment(expTime);
    if (b.diff(a, 'days') <= 1) {
      refresh_token({
        refreshToken
      }).then(res => {
        store.commit('SET_TOKEN', res.token);
        store.commit('SET_REFRESH_TOKEN', res.refreshToken);
        store.commit('SET_EXPTIME', res.expTime);
      })
    }
  }
})
