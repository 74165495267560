<template>
  <div class="breadcrumb-header">
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item
        v-for="(b, i) in bread"
        :key="i"
      >
        <a @click="goback" v-if="b.path">{{b.title}}</a>
        <span v-else>{{b.title}}</span>
      </el-breadcrumb-item>
    </el-breadcrumb>
  </div>
</template>

<script>
import { bread } from "@/config/breadcrumb";

export default {
  name: "breadcrumb-header",
  components: {
  },
  data() {
    return {
      bread: []
    }
  },
  watch: {
    $route() {
      this.getBreadcrumb();
    }
  },
  created() {
  },
  mounted() {
    this.getBreadcrumb();
  },
  methods: {
    getBreadcrumb() {
      console.log('getBreadcrumb', this.$route.meta)

      this.bread = bread(this.$route && this.$route.meta || {})
    },
    goback() {
      // const keepAlive = this.$store.state.page.keepAlive.concat(['finance-invoice'])
      // this.$store.commit('page/SET_KEEPALIVE', keepAlive)
      this.$router.go(-1)
    }
  }
}
</script>

<style scoped lang="scss">
  .breadcrumb-header {
    flex: 0 0 40px;
    padding: 0 20px;
    box-sizing: border-box;
    background: #fff;
    z-index: 999;
    display: flex;
    align-items: center;
    .el-breadcrumb__item {
      font-size: 14px;
      color: #666666;
    }
  }
</style>