import { ajax } from "@/api/request";

// 码表查询
export const get_key_value = function(data) {
  return ajax({
    method: 'post',
    url: '/sys/v1/pageListDict',
    data
  });
}

// 省市区查询
export const get_area_list = function(data) {
  return ajax({
    method: 'post',
    url: '/sys/v1/getAreaList',
    data
  });
}

// 上传文件
export const upload_file = function(data) {
  return ajax({
    method: 'post',
    url: '/user/v1/upload',
    data,
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded'
    }
  });
}

// 权限查询
export const check_config_key = function(data) {
  return ajax({
    method: 'post',
    url: '/sys/v1/checkConfigKey',
    data
  });
}

//埋点
export const post_errorLog = function(data) {
  return ajax({
    url: '/log/v1/saveErrorLog',
    method: 'post',
    data
  });
}