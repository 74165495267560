// import Home from '@/assets/nav/business-w.png';
// import HomeActive from '@/assets/nav/business-b.png'
// import Market from '@/assets/nav/market-w.png';
// import MarketActive from '@/assets/nav/market-b.png';
// import Product from '@/assets/nav/product-w.png';
// import ProductActive from '@/assets/nav/product-b.png';
// import Finance from '@/assets/nav/finance-w.png';
// import FinanceActive from '@/assets/nav/finance-b.png';
// import User from '@/assets/nav/user-w.png';
// import UserActive from '@/assets/nav/user-b.png';
// import Data from '@/assets/nav/data-w.png';
// import DataActive from '@/assets/nav/data-b.png';

import { validMenuRoutes } from '@/router'

export const navList = function() {
  let a = []
  validMenuRoutes().map(o => {
    const { icon, active } = o
    const { id, parentId, menu, permission, title, menuName } = o.meta
    if (menu) {
      const permissionModule = permission ? permission.split(':')[0] : ''
      const one = parentId
      if (!a[one - 1])
        a[one - 1] = {
          id: one,
          label: menuName,
          icon,
          active,
          permission: permissionModule,
          children: []
        }
      a[one - 1].children.push({
        id: id,
        parentId: parentId,
        path: o.path,
        permission: permission,
        label: title
      })
    }
  })
  a = a.map(v => {
    if (v.children.length == 1 && v.children[0].id == v.children[0].parentId) {
      v.path = v.children[0].path
      v.children = null
    }
    return v
  })
  console.log('左侧菜单', a)
  return a
}
// [
//   {
//     id: 1,
//     label: '酒店管理',
//     icon: Home,
//     active: HomeActive,
//     permission: 'hotel',
//     children: [
//       {
//         id: 11,
//         path: '/hotel/hotel-list',
//         permission: 'hotel:manager',
//         label: '酒店列表'
//       },
//       {
//         id: 12,
//         path: '/hotel/hotel-recharge',
//         permission: 'hotel:manager',
//         label: '续期记录'
//       }
//     ]
//   },
//   {
//     id: 2,
//     label: '销售管理',
//     icon: Market,
//     active: MarketActive,
//     permission: 'market',
//     children: null
//   },
//   {
//     id: 3,
//     path: '/product',
//     label: '产品管理',
//     icon: Product,
//     active: ProductActive,
//     permission: 'product',
//     children: null
//   },
//   {
//     id: 4,
//     label: '财务管理',
//     icon: Finance,
//     active: FinanceActive,
//     permission: 'finance',
//     children: [
//       {
//         id: 41,
//         path: '/finance/invoice',
//         permission: 'finance:invoice',
//         label: '开票管理'
//       },
//     ]
//   },
//   {
//     id: 5,
//     label: '用户与权限管理',
//     icon: User,
//     active: UserActive,
//     permission: 'user',
//     children: [
//       {
//         id: 51,
//         path: '/user/system-user',
//         permission: 'user:user',
//         label: '用户管理'
//       },
//       {
//         id: 52,
//         path: '/user/system-role',
//         permission: 'user:role',
//         label: '角色管理'
//       },
//       {
//         id: 53,
//         path: '/user/system-auth',
//         permission: 'user:auth',
//         label: '权限配置'
//       }
//     ]
//   },
//   {
//     id: 6,
//     label: '基础数据维护',
//     icon: Data,
//     active: DataActive,
//     permission: 'base',
//     children: [
//       {
//         id: 61,
//         path: '/base/hotel-system-auth',
//         label: '酒店系统权限配置'
//       },
//     ]
//   }
// ]
