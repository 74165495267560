export const arrayToMap = function(array) {
  let map = {}
  array.map(o => {
    map[o.k] = o.v
  })
  return map
}

export const arrayToConst = function(array) {
  let constA = {}
  array.map(o => {
    constA[o.type] = o.k
  })
  return constA
}