import Vue from 'vue'
import Vuex from 'vuex'
import {getStorage, setStorage} from "@/utils/storage";
Vue.use(Vuex)
import page from './modules/page'
import workflow from '@/components/workflow/workflow'

export const store = new Vuex.Store({
  modules:{
    page,
    workflow
  },
  state: {
    token: getStorage('token') || '',
    refreshToken: getStorage('refreshToken') || '',
    expTime: getStorage('expTime') || '',
    userInfo: {},
    permissions: [],
    adminCode: 'RS00'
  },
  mutations: {
    SET_TOKEN(state, token) {
      setStorage('token', token)
      state.token = token;
    },
    SET_REFRESH_TOKEN(state, refreshToken) {
      setStorage('refreshToken', refreshToken)
      state.refreshToken = refreshToken;
    },
    SET_EXPTIME(state, expTime) {
      setStorage('expTime', expTime)
      state.expTime = expTime;
    },
    SET_USERINFO(state, userInfo) {
      state.userInfo = userInfo;
    },
    SET_PERMISSIONS(state, permissions) {
      state.permissions = permissions;
    }
  }
})