<template>
 <div class="page-container">
   无权限 
   <el-button @click="goHome">返回首页</el-button>
 </div>
</template>

<script>

export default {
  name: "empty",
  components: {
  },
  methods: {
    goHome() {
      this.$router.replace({
        path: '/'
      })
    }
  }
}
</script>

<style scoped lang="scss">
</style>