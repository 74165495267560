import Vue from 'vue'
import App from './App.vue'
import ElementUI from 'element-ui';
import VueAMap from 'vue-amap'
import './assets/element-variables.scss';
import './assets/common.css';
import './assets/common.scss';
import './assets/flex.css';
import './assets/iconfont/iconfont.css';
import './icons'
import './icons/style.css'
// import './assets/quill.scss';

import { store } from "@/store";
import { router } from "@/router";

import { deepClone } from '@/utils'
import { commaNum } from '@/utils/commaNum'
import { arrayToMap } from '@/utils/constTransform'
Vue.prototype.$deepClone = deepClone
Vue.prototype.$commaNum = commaNum
Vue.prototype.$arrayToMap = arrayToMap

Vue.use(ElementUI);

Vue.use(VueAMap)
VueAMap.initAMapApiLoader({
  key: window._amapKey,
  plugin: [
    'AMap.Geocoder',
    'AMap.Scale',
    'AMap.PlaceSearch'
  ],
  // 默认高德 sdk 版本为 1.4.4
  v: '1.4.4'
})

import Highcharts from 'highcharts/highcharts';
import HighchartsVue from 'highcharts-vue';
import stockInit from 'highcharts/modules/stock';
// import exportingInit from 'highcharts/modules/exporting';
stockInit(Highcharts);
// exportingInit(Highcharts);
Highcharts.setOptions({
  lang: {
    exitFullscreen: "退出全屏",
    viewFullscreen:"全屏",
    contextButtonTitle: "图表导出菜单",
    decimalPoint: ".",
    downloadJPEG: "下载JPEG图片",
    downloadPDF: "下载PDF文件",
    downloadPNG: "下载PNG文件",
    downloadSVG: "下载SVG文件",
    printChart: "打印图表",
  }
})
Vue.use(HighchartsVue, {
  tagName: 'high-charts'
});

Vue.config.productionTip = false

new Vue({
  store,
  router,
  render: h => h(App),
}).$mount('#app')
