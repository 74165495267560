export const commaNum = function (num) {
  if (num === '' || num === null || num === undefined) return ''
  const numArr = (num * 1 + '').split('.');
  const s1 = numArr[1] ? '.' + numArr[1] : ''
  const n = numArr[0] + '';
  const l = n.length % 3;
  if (l === n.length) {
    return n + s1;
  }
  const start = n.slice(0, l) + (l ? ',' : '')
  let i = 0;
  const n1 = n.slice(l);
  const arr = [];
  while (n1[i]) {
    arr.push(n1.slice(i, i + 3));
    i+= 3;
  }
  return start + arr.toString() + s1;
}