const page = {
  namespaced: true,
  state: {
    keepAlive: []
  },
  getters: {
  },
  mutations: {
    SET_KEEPALIVE(state, keepAlive) {
      state.keepAlive = keepAlive
      console.log(state.keepAlive)
    }
  },
  actions: {
    HandleKeepAlivePage: ({ state, commit }, page) => {
      // 加入页面缓存
      const index = state.keepAlive.indexOf(page)
      if (index < 0) {
        const keepAlive = state.keepAlive.concat([page])
        commit('SET_KEEPALIVE', keepAlive)
      }
    },
    ClearKeepAlivePage: ({ state, commit }, page) => {
      // 清除页面缓存
      const index = state.keepAlive.indexOf(page)
      if (index > -1) {
        let keepAlive = state.keepAlive.concat()
        keepAlive.splice(index, 1)
        commit('SET_KEEPALIVE', keepAlive)
      }
    },
    HandleClearKeepAlive: ({ commit }) => {
      commit('SET_KEEPALIVE', [])
    }
  }
  
}
export default page