// vuex

const workflow = {
  namespaced: true,
  state: {
    design: {},
    selectedNode: {},
    nodeMap: new Map(),
    selectFormItem: null,
    needMsgFlag: false,
    msgTemplateType: ''
  },
  getters: {
  },
  mutations: {
    setDesign(state, data) {
      state.design = data
    },
    setSelectedNode(state, data) {
      state.selectedNode = data
    },
    setNeedMsgFlag(state, data) {
      state.needMsgFlag = data
    },
    setMsgTemplateType(state, data) {
      state.msgTemplateType = data
    },
  }
  
}
export default workflow