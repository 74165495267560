import { routes } from '@/router'

export const bread = function(meta) {
  const { id, parentId, menuName, title } = meta
  let a = [
    {
      title: menuName,
      path: ''
    }
  ]
  if (parentId != id) {
    const parent = routes.find(o => { return o.meta.id == parentId })
    if (parent) {
      a = a.concat([
        {
          title: parent.meta.title,
          path: parent.path
        }
      ])
    }
    a = a.concat([
      {
        title: title,
        path: ''
      }
    ])
  }
  console.log(a)
  return a
}