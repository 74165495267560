<template>
  <div class="container">
    <slide-bar></slide-bar>
    <div class="content">
      <index-header></index-header>
      <breadcrumb-header></breadcrumb-header>
      <div class="main scroll">
        <keep-alive :include="includeList">
          <router-view>
            <!-- 这里是会被缓存的视图组件，比如 Home！ -->
          </router-view>
        </keep-alive>
      </div>
    </div>
  </div>
</template>

<script>
import { get_user_info } from "@/api/login";
import SlideBar from './component/slide-bar';
import IndexHeader from './component/index-header';
import BreadcrumbHeader from './component/breadcrumb-header';

export default {
  name: "index",
  components: {
    SlideBar,
    IndexHeader,
    BreadcrumbHeader
  },
  data() {
    return {
      includeList: ['finance-invoice', 'hotel-list', 'minip-list', 'marketer-list', 'hotel-initialization'],
      excludeList: []
    }
  },
  computed: {
  },
  watch: {
  },
  async created() {
    if (this.$store.state.token) {
      const res = await get_user_info()
      console.log(res)
      this.$store.commit('SET_USERINFO', res || {})
    }
  },
  mounted() {
  },
  methods: {
  }
}
</script>

<style scoped lang="scss">
  .container {
    height: 100vh;
    display: flex;
    flex-direction: row;
    overflow: hidden;
    .content {
      flex: 1;
      background: #F0F2F5;
      display: flex;
      flex-direction: column;
      overflow: hidden;
      .main {
        flex: 1;
        box-sizing: border-box;
        padding: 24px;
        overflow: auto;
      }
    }
  }
</style>
