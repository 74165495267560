import { ajax } from "@/api/request";
import qs from 'qs';

export const user_login = function (data) {
  return ajax({
    method: 'post',
    url: '/token/v1/getTokenWithCode',
    data
  });
}

export const update_pwd_by_token = function (data) {
  return ajax({
    method: 'post',
    url: `/user/v1/updatePwdByToken?${qs.stringify(data)}`
  });
}

export const refresh_token = function (data) {
  return ajax({
    method: 'post',
    url: '/token/v1/refreshToken',
    data
  });
}

// 获取登录用户信息
export const get_user_info = function(data) {
  return ajax({
    url: '/user/v1/getUserInfo',
    params: data
  });
}