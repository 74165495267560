<template>
  <div class="login-page">
    <div class="login-div flex">
      <div class="login-left"></div>
      <div class="login-form flex-1">
        <div class="title">
          鼎壹运维管理后台
        </div>
        <div class="item">
          <el-input v-model="username" placeholder="请输入登录账号">
            <span slot="prefix" class="icon-username"></span>
          </el-input>
        </div>
        <div class="item">
          <el-input :type="see[1] ? 'text' : 'password'" v-model="password"  placeholder="请输入密码" @keyup.enter.native="handleLogin">
            <span slot="prefix" class="icon-password"></span>
            <span slot="suffix" :class="see[1] ? 'icon-see' : 'icon-seeun'" @click="changeSee(1)"></span>
          </el-input>
        </div>
        <div class="item">
          <el-input v-model="code" placeholder="请输入验证码" @keyup.enter.native="handleLogin">
            <span slot="prefix" class="icon-code"></span>
          </el-input>
          <div @click="freshCode" class="code-img">
            <img :src="codeSrc" width="100%" height="100%" alt="">
          </div>
        </div>
        <div class="item">
          <div class="login-msg" v-show="msg">
            <i class="el-icon-warning"></i> {{msg}}
          </div>
          <el-button class="login-btn" type="primary" @click="handleLogin">登录</el-button>
        </div>
      </div>
    </div>
    <div class="beian" >
        <div>© 2016-2025 zhidianfan.com 版权所有 <a href="https://beian.miit.gov.cn/" target="_blank" class="a-link">浙ICP备16031560号-1</a></div>
        <div style="margin-top:5px"><img src="https://download-dy.oss-cn-hangzhou.aliyuncs.com/beian.png" style="width:16px;margin-right: 5px;display: inline-block;vertical-align: -3px;"><a href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=33021202001232" target="_blank" class="a-link">浙公网安备 33021202001232号</a></div>
    </div>
  </div>
</template>

<script>
import { base_url } from "@/config";
import { user_login, get_user_info } from "@/api/login";
import {save_login_logs} from "@/api/log";

export default {
  name: "login",
  data() {
    return {
      username: '',
      password: '',
      code: '',
      timeStamp: Date.now(),
      msg: '',
      see: {
        1: false
      }
    }
  },
  computed: {
    codeSrc() {
      const src = base_url + '/sys/v1/getCaptcha?code_id=' + this.timeStamp;
      return src;
    }
  },
  mounted() {
    this.freshCode()
  },
  methods: {
    changeSee(key) {
      this.see[key] = !this.see[key]
    },
    checkForm() {
      const arr = [
        {
          key: 'username',
          label: '登录账号不能为空！'
        },
        {
          key: 'password',
          label: '登录密码不能为空！'
        },
        {
          key: 'code',
          label: '验证码不能为空！'
        }
      ];
      for (const v of arr) {
        const value = this[v.key];
        if (!value) {
          this.msg = v.label
          return false;
        }
      }
      return true;
    },
    freshCode() {
      this.timeStamp = Date.now();
    },
    handleLogin() {
      this.msg = ''
      const res = this.checkForm();
      if (!res) {
        return;
      }
      const d = {
        username: this.username,
        password: this.password,
        imgCode: this.code,
        clientType: "DINGYI_MANAGER"
      };
      user_login(d)
        .then(res => {
          console.log('login res', res);
          this.$store.commit('SET_TOKEN', res.token);
          this.$store.commit('SET_REFRESH_TOKEN', res.refreshToken);
          this.$store.commit('SET_EXPTIME', res.expTime);
          get_user_info().then(res => {
            this.$store.commit('SET_USERINFO', res || {})
            save_login_logs({
              clientType: 'DINGYI_MANAGER',
              loginType: 1,
              username: this.username
            })
          })
          this.$router.replace({
            path: '/'
          })
        })
        .catch(err => {
          console.log('err', err);
          this.msg = err.msg
          this.freshCode();
        });
    }
  }
}
</script>

<style scoped lang="scss">
  .login-page {
    height: 100vh;
    position: relative;
    background: url(../../assets/img/login-bg.png);
    background-size: cover;
    background-repeat: no-repeat;
    text-align: center;
    .login-div {
      z-index: 100;
      width: 80%;
      background: transparent;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      .login-left{
        width: 60%;
        height: 624px;
        background: #FFF8F8 url(../../assets/img/login-img.png);
        background-position: center center;
        background-size: 80%;
        background-repeat: no-repeat;
        border-radius: 20px 0 0 20px;
      }
      .login-form{
        background: #ffffff;
        border-radius: 0 20px 20px 0;
        .title {
          font-size: 32px;
          font-weight: bold;
          color: #C30D23;
          padding: 50px 0;
          &:before{
            content: '';
            display: block;
            margin: 0 auto 20px;
            background: url(../../assets/img/logo.png);
            width: 57px;
            height: 60px;
          }
        }
        .item {
          margin-bottom: 20px;
          display: flex;
          align-items: center;
          min-width: 300px;
          margin: 0 auto 20px;
          padding: 0 10%;
          position: relative;
          ::v-deep .el-input__prefix{
            left: 15px;
            display: flex;
            align-items: center;
          }
          ::v-deep .el-input__suffix{
            right: 15px;
            display: flex;
            align-items: center;
          }
          ::v-deep input.el-input__inner {
            border: 1px solid #EEEEEE;
            padding-left: 60px;
            padding-right: 60px;
            height: 50px;
            line-height: 50px;
            border-radius: 50px;
            &:focus{
              border: 1px solid #666666 !important;
              & + .el-input__prefix .icon-username{
                background: url(../../assets/icons/username2.png) no-repeat center center/100% 100%;
              }
              & + .el-input__prefix .icon-password{
                background: url(../../assets/icons/password2.png) no-repeat center center/100% 100%;
              }
              & + .el-input__prefix .icon-code{
                background: url(../../assets/icons/code2.png) no-repeat center center/100% 100%;
              }
            }
          }
          .code-img {
            width: 100px;
            height: 50px;
            margin-left: 35px;
            flex: 0 0 auto;
            cursor: pointer;
          }
          .login-msg{
            color: #F56C6C;
            font-size: 18px;
            position: absolute;
            width: 100%;
            top: 0;
            left: 0;
          }
        }
        .icon-username{
          display: block;
          width: 27px;
          height: 27px;
          background: url(../../assets/icons/username1.png) no-repeat center center/100% 100%;
        }
        .icon-password{
          display: block;
          width: 27px;
          height: 27px;
          background: url(../../assets/icons/password1.png) no-repeat center center/100% 100%;
        }
        .icon-code{
          display: block;
          width: 27px;
          height: 27px;
          background: url(../../assets/icons/code1.png) no-repeat center center/100% 100%;
        }
        .icon-see{
          display: block;
          width: 27px;
          height: 16px;
          background: url(../../assets/icons/see.png) no-repeat center center/100% 100%;
        }
        .icon-seeun{
          display: block;
          width: 27px;
          height: 12px;
          background: url(../../assets/icons/see-un.png) no-repeat center center/100% 100%;
        }
      }
      .login-btn {
        width: 100%;
        height: 60px;
        border-radius: 60px;
        margin-top: 40px;
        font-size: 26px;
        background: #C30D23;
        border-color: #C30D23;
      }
    }
    .beian{
      font-size:12px;
      text-align: center;
      color: #666;
      position: absolute;
      bottom: 20px;
      left: 50%;
      width: 400px;
      margin-left: -200px;
      a{
        color: #666;
        text-decoration: none;
      }
      a:hover{
        text-decoration: underline;
      }
    }
  }
</style>
